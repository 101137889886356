import style from "./style.module.css";
import { useState } from "react";
import { useSession } from "../../providers/session-provider";
import { login, signup } from "../../lib/auth";

export function LoginSignup() {
  const [isLoginPage, setIsLoginPage] = useState(true);
  const toggleLoginSignup = () => setIsLoginPage(!isLoginPage);
  return (
    <div className={style.container}>
      {isLoginPage ? (
        <Login toggleLoginSignup={toggleLoginSignup} />
      ) : (
        <Signup toggleLoginSignup={toggleLoginSignup} />
      )}
    </div>
  );
}

function Login({ toggleLoginSignup }: { toggleLoginSignup: () => void }) {
  const {
    actions: { setSessionToken },
  } = useSession();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    try {
      let token = await login(username, password);
      setSessionToken(token);
    } catch (e) {
      console.error("Error logging in", e);
      setError(String(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h1>Wordsario Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="username"
          disabled={loading}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button disabled={loading} type="submit">
          Log in
        </button>
        {error && <div className={style.error}>Oops! An error occured.</div>}
      </form>
      <p>
        Don't have a login?{" "}
        <span className={style.signupLink} onClick={toggleLoginSignup}>
          Sign up
        </span>
        .
      </p>
    </>
  );
}

function Signup({ toggleLoginSignup }: { toggleLoginSignup: () => void }) {
  const {
    actions: { setSessionToken },
  } = useSession();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    try {
      let token = await signup(username, password);
      setSessionToken(token);
    } catch (e) {
      console.error("Error signing up", e);
      setError(String(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h1>Wordsario Signup</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="username"
          disabled={loading}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="password"
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button disabled={loading} type="submit">
          Sign up
        </button>
        {error && <div className={style.error}>Oops! An error occured.</div>}
      </form>
      <p>
        Already signed up?{" "}
        <span className={style.signupLink} onClick={toggleLoginSignup}>
          Log in
        </span>
        .
      </p>
    </>
  );
}
