import React, { useEffect, useState } from "react";
import { useCollections } from "../../providers/collections-provider";
import { Collection } from "../../providers/collections-provider/types";
import style from "./Game.module.css";
import { useGoogleAnalytics } from "../../providers/google-analytics-provider";

function randomItem<T>(arr: T[]) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function getImages(collection: Collection) {
  return collection.images.slice(0, 3);
}

export function Game() {
  const {
    state: { collections },
  } = useCollections();
  const { sendEvent } = useGoogleAnalytics();
  const [now, setNow] = useState(window.performance.now());

  const [currentColls, setCurrentColls] = useState<[Collection, Collection]>([
    randomItem(collections),
    randomItem(collections),
  ]);
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const [curr, next] = currentColls;
      setImages(getImages(curr));
      getImages(next).forEach((url) => {
        const img = new Image();
        img.src = url;
        img.onload = function () {
          console.log("loaded", url);
        };
      });
    };
    fetchData().catch((e) => console.error("failed to fetch data", e));
  }, [currentColls]);

  const moveToNextStep = () => {
    const newNow = window.performance.now();
    const diff = newNow - now;
    setNow(newNow);
    sendEvent("step_completed", {
      step_duration: diff,
    });

    if (collections.length === 1) {
      setCurrentColls([collections[0], collections[0]]);
      return;
    }

    const next = currentColls[1];
    // we don't want to show the same collection twice in a row
    const others = collections.filter((c) => c !== next);
    setCurrentColls([next, randomItem(others)]);
  };

  return (
    <div className={"gameArea"} onClick={moveToNextStep}>
      <Moon images={images} />
    </div>
  );
}

function Moon({ images }: { images: string[] }) {
  return (
    <div className={style.Moon}>
      {images.map((url) => (
        <img src={url} key={url} alt="moon" />
      ))}
    </div>
  );
}
