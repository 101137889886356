import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Collection,
  CollectionsContextValue,
  emptyCollectionsContextValue,
  setCollectionImages,
} from "./types";

const CollectionsContext = createContext<CollectionsContextValue>(
  emptyCollectionsContextValue
);

export function useCollections() {
  return useContext(CollectionsContext);
}

const collectionsKey = "_collections";
const defaultCollections: Collection[] = [];

export function loadCollections() {
  const cached = localStorage.getItem(collectionsKey);
  console.log("loadCollections cache", cached);
  if (cached) {
    const collections = JSON.parse(cached);
    if (Array.isArray(collections) && collections.length) {
      return collections;
    }
  }
  return defaultCollections;
}

const initialCollections = loadCollections();

export function CollectionsProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [collections, setCollections] =
    useState<Collection[]>(initialCollections);

  useEffect(() => {
    localStorage.setItem(collectionsKey, JSON.stringify(collections));
  }, [collections]);

  const value = {
    state: { collections },
    actions: {
      setCollections,
      addCollection: (name: string) => {
        setCollections([...collections, { name, images: [] }]);
      },
      setImages: (name: string, images: string[]) => {
        const c = setCollectionImages(collections, name, images);
        setCollections(c);
      },
      resetCollections: () => setCollections(defaultCollections),
    },
  };

  return (
    <CollectionsContext.Provider value={value}>
      {children}
    </CollectionsContext.Provider>
  );
}
