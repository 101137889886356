import React, { ChangeEventHandler, useState } from "react";
import style from "./WordList.module.css";
import { useNavigate } from "react-router-dom";
import { useCollections } from "../../providers/collections-provider";
import { ModalView } from "../modal-view";
import { searchImageURLs } from "../../lib/image-lookup";
import { useGoogleAnalytics } from "../../providers/google-analytics-provider";
import { useSession } from "../../providers/session-provider";

export function WordList() {
  const {
    state: { collections },
    actions: { setCollections },
  } = useCollections();
  const { sendEvent } = useGoogleAnalytics();
  const {
    state: { sessionToken },
    actions: { setSessionToken },
  } = useSession();

  const [words, setWords] = useState<string[]>(collections.map((c) => c.name));
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState({ total: 0, current: 0 });
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const updateWords: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    let words = e.target.value.split("\n");
    setWords(words);
  };

  const reviewButtonClicked = async () => {
    try {
      setDisabled(true);
      setProgress({ total: words.length, current: 0 });
      const newCollections = [];
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        if (word.length === 0) {
          continue;
        }
        let images: string[] = [];
        let c = collections.find((c) => c.name === word);
        if (c) {
          images = c.images;
        } else {
          images = await searchImageURLs(word, sessionToken);
          sendEvent("search", {
            search_term: word,
          });
        }
        const collection = {
          name: word,
          images,
        };
        newCollections.push(collection);
        setProgress({ total: words.length, current: i + 1 });
      }
      console.log("collections", newCollections);
      setCollections(newCollections);
      navigate("/review");
    } catch (e) {
      console.error("failed to load images", e);
      sendEvent("error", {
        component: "reviewButtonClicked",
        error_msg: String(e),
      });
    } finally {
      setDisabled(false);
      setProgress({ total: 0, current: 0 });
    }
  };

  return (
    <div className={style.container}>
      <h1>Wordsario Home</h1>
      <h3 className={style.instructions} onClick={() => setShowModal(true)}>
        Instructions
      </h3>
      <br />
      <textarea
        disabled={disabled}
        className={style.wordList}
        value={words.join("\n")}
        onChange={updateWords}
        placeholder={"Enter your list of words"}
      />
      <br />
      <button
        onClick={reviewButtonClicked}
        disabled={disabled}
        className={style.reviewButton}
      >
        {progress.current > 0
          ? `${Math.floor(
              (100 * progress.current) / progress.total
            )}% loading...`
          : "Review"}
      </button>
      <button
        onClick={() => {
          setCollections([]);
          localStorage.clear();
          setSessionToken("");
        }}
      >
        Debug clear data
      </button>
      <br />
      <div className={style.legalLinks}>footer/legal/faq</div>
      <ModalView visible={showModal} onClose={() => setShowModal(false)}>
        <div className={style.modalContent}>
          <h3>Wordsario</h3>
          <p>👋 Welcome! Thanks for stopping by!</p>
          <p>
            To get started, add a line in the text box with the topic of images
            you want to gather.
          </p>
          <p>
            Each line gets turned into a search term for Bing image search, so
            it might take a couple iterations of a term to get exactly the
            images you want.
          </p>
          <p>
            You can then review the images the search brings up. Tapping an
            image will cycle to one of the next search results.
          </p>
          <p>
            Once entering the play mode, tapping anywhere on the screen will
            take you to a different random set of images.
          </p>
        </div>
      </ModalView>
    </div>
  );
}
