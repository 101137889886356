import React from "react";

// Import providers here
import { OnlyOnePlayerProvider } from "./example-provider";
import { CollectionsProvider } from "./collections-provider";
import { GoogleAnalyticsProvider } from "./google-analytics-provider";
import { SessionProvider } from "./session-provider";

// Add providers here
const providers = [
  OnlyOnePlayerProvider,
  CollectionsProvider,
  GoogleAnalyticsProvider,
  SessionProvider,
];

type C = React.ComponentType<any>;

type Props = {
  children?: C;
};

function initialComponent({ children }: Props) {
  return <>{children}</>;
}

function wrapComponents(AccumulatedComponents: C, CurrentComponent: C) {
  return function ({ children }: Props) {
    return (
      <AccumulatedComponents>
        <CurrentComponent>{children}</CurrentComponent>
      </AccumulatedComponents>
    );
  };
}

function combineComponents(...components: C[]) {
  return components.reduce(wrapComponents, initialComponent);
}

export const AppContextProvider = combineComponents(...providers);
