import React, { createContext, useContext, useEffect, useState } from "react";

const SessionContext = createContext({
  state: { sessionToken: "" },
  actions: { setSessionToken: (token: string) => {} },
});

export function useSession() {
  return useContext(SessionContext);
}

const initialSessionToken = localStorage.getItem("sessionToken") || "";

export function SessionProvider({ children }: { children?: React.ReactNode }) {
  const [sessionToken, setSessionToken] = useState<string>(initialSessionToken);

  useEffect(() => {
    localStorage.setItem("sessionToken", sessionToken);
  }, [sessionToken]);

  const value = {
    state: { sessionToken },
    actions: { setSessionToken },
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}
