import React, { createContext, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const GoogleAnalyticsContext = createContext({
  sendEvent: (name: string, params: any) => {},
});

export function useGoogleAnalytics() {
  return useContext(GoogleAnalyticsContext);
}

export function GoogleAnalyticsProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  const sendEvent = (name: string, params: any) => {
    if (initialized) {
      ReactGA.event(name, params);
    }
  };

  useEffect(() => {
    ReactGA.initialize("G-SPWW3JJ4BE");
    ReactGA.send({ hitType: "pageview", page: "/" });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [initialized, location]);

  return (
    <GoogleAnalyticsContext.Provider value={{ sendEvent }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}
