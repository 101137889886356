import { useState } from "react";
import { searchImageURLs } from "../../lib/image-lookup";
import { useSession } from "../../providers/session-provider";

type Props = { imagesAdder: (imageUrls: string[]) => void };

export function SearchForImage({ imagesAdder }: Props) {
  const {
    state: { sessionToken },
  } = useSession();

  const [searchQuery, setSearchQuery] = useState("");
  const [images, setImages] = useState<string[]>([]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const imgs = await searchImageURLs(searchQuery, sessionToken);
    setImages(imgs);
    setSearchQuery("");
  };

  const addAllImages = () => {
    imagesAdder(images);
  };

  return (
    <div>
      <h1>Search For Image</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      {images.length > 0 && (
        <>
          <button onClick={addAllImages}>Add All</button>
          <div className="images">
            {images.map((url) => (
              <img
                src={url}
                key={url}
                alt="moon"
                onClick={() => imagesAdder([url])}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
