import { ep } from "./api-endpoint";

export async function login(
  username: string,
  password: string
): Promise<string> {
  const response = await fetch(ep(`/auth/login`), {
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ username, password }),
  });
  const data = await response.json();
  return data.session_token;
}

export async function signup(
  username: string,
  password: string
): Promise<string> {
  const response = await fetch(ep(`/auth/signup`), {
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ username, password }),
  });
  const data = await response.json();
  return data.session_token;
}
