import React, { createContext, useContext, useState } from "react";

const OnlyOnePlayerContext = createContext({ state: {}, actions: {} });

export function useOnlyOnePlayer() {
  return useContext(OnlyOnePlayerContext);
}

export function OnlyOnePlayerProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [nowPlayingID, setNowPlayingID] = useState(-1);

  const value = {
    state: { nowPlayingID },
    actions: { setNowPlayingID },
  };

  return (
    <OnlyOnePlayerContext.Provider value={value}>
      {children}
    </OnlyOnePlayerContext.Provider>
  );
}
