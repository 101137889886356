import { useParams } from "react-router-dom";
import { SearchForImage } from "../search-for-image";
import React, { useEffect, useState } from "react";
import { useCollections } from "../../providers/collections-provider";
import { setCollectionImages } from "../../providers/collections-provider/types";
import { NavLinks } from "../header";

export function CollectionEditor() {
  const { name } = useParams<{ name: string }>();

  const {
    state: { collections },
    actions: { setCollections },
  } = useCollections();

  const collection = collections.find((c) => c.name === name);
  const initialImages = collection?.images || [];

  const [images, setImages] = useState<string[]>(initialImages);

  useEffect(() => {
    if (name) {
      const c = setCollectionImages(collections, name, images);
      console.log("updating collection", c);
      setCollections(c);
    }
  }, [setCollections, collections, images, name]);

  const handleDelete = (image: string) => {
    setImages(images.filter((i) => i !== image));
  };

  return (
    <div>
      <NavLinks />
      <h1>Collection Editor</h1>
      <p>{name}</p>
      <div className="images">
        {images.map((url) => (
          <img
            src={url}
            key={url}
            onClick={() => handleDelete(url)}
            alt="moon"
          />
        ))}
      </div>
      <SearchForImage
        imagesAdder={(imgs) => {
          console.log("adding images", imgs);
          setImages([...images, ...imgs]);
        }}
      />
    </div>
  );
}
