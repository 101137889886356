import style from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useCollections } from "../../providers/collections-provider";
import { ReviewListItem } from "./ReviewListItem";
import { useState } from "react";
import { useGoogleAnalytics } from "../../providers/google-analytics-provider";

export function ReviewList() {
  const {
    state: { collections },
  } = useCollections();
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();
  const { sendEvent } = useGoogleAnalytics();

  const playButtonHandler = () => {
    try {
      setDisabled(true);
      sendEvent("start_play", {});
      navigate("/play");
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className={style.container}>
      <h1>Review list</h1>
      <div>
        <ol>
          {collections.map((collection) => (
            <li key={collection.name}>
              <ReviewListItem collectionName={collection.name} />
            </li>
          ))}
        </ol>
      </div>
      <div className={style.navButtons}>
        <Link to="/">
          <button disabled={disabled}>Revise list</button>
        </Link>
        <button disabled={disabled} onClick={playButtonHandler}>
          Start playing
        </button>
        <br />
        <button
          disabled={disabled || copied}
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(collections, null, 2));
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
        >
          {copied ? "Copied!" : "Debug copy collection"}
        </button>
      </div>
    </div>
  );
}
