import fetchRetry from "fetch-retry";
import { ep } from "./api-endpoint";

const f = fetchRetry(fetch, {
  retries: 10,
  retryOn: [503],
  retryDelay: function (
    attempt: number,
    error: Error | null,
    response: Response | null
  ): number {
    return Math.pow(2, attempt) * 100;
  },
});

type ImageURL = string;

export async function searchImageURLs(
  query: string,
  sessionToken: string
): Promise<ImageURL[]> {
  const cachedImageURLs = await getCachedImageURLs(query);
  if (cachedImageURLs.length > 0) {
    return cachedImageURLs;
  }
  const imageURLs = await fetchImagesFromWeb(query, sessionToken);
  await setCachedImageURLs(query, imageURLs);
  return imageURLs;
}

async function getCachedImageURLs(query: string): Promise<ImageURL[]> {
  const cachedImageURLs = localStorage.getItem(query);
  if (cachedImageURLs) {
    return JSON.parse(cachedImageURLs);
  }
  return [];
}

async function setCachedImageURLs(
  query: string,
  imageURLs: ImageURL[]
): Promise<void> {
  localStorage.setItem(query, JSON.stringify(imageURLs));
}

async function fetchImagesFromWeb(
  query: string,
  sessionToken: string
): Promise<ImageURL[]> {
  const queryParams = new URLSearchParams({ query: query });
  const response = await f(
    ep(`/api/v1/image-search?${queryParams.toString()}`),
    {
      method: "get",
      headers: new Headers({
        Authorization: sessionToken,
      }),
    }
  );
  const data = await response.json();
  let imageURLs: ImageURL[] = [];
  if (data.images && data.images.length > 0) {
    imageURLs = data.images.map((image: { url: string }) => image.url);
  }
  return imageURLs;
}
