import style from "./style.module.css";
import { useCollections } from "../../providers/collections-provider";
import { useMemo } from "react";
import { useGoogleAnalytics } from "../../providers/google-analytics-provider";

type Props = {
  collectionName: string;
};

export function ReviewListItem({ collectionName }: Props) {
  const {
    state: { collections },
    actions: { setImages, setCollections },
  } = useCollections();
  const { sendEvent } = useGoogleAnalytics();

  const collection = collections.find((c) => c.name === collectionName);
  const images = useMemo(() => {
    return collection?.images || [];
  }, [collection]);

  const rotateImage = (image: string) => {
    setImages(collectionName, [...images.filter((i) => i !== image), image]);
    sendEvent("image_rejected", {
      search_term: collectionName,
    });
  };

  const handleDelete = (collectionName: string) => {
    setCollections(collections.filter((c) => c.name !== collectionName));
  };

  const visibleImages = images.slice(0, 3);

  return (
    <div className={style.itemContainer}>
      <span>{collectionName}</span>
      {visibleImages.map((url) => (
        <img
          src={url}
          key={url}
          alt={`${collectionName}`}
          onClick={() => rotateImage(url)}
        />
      ))}
      <button onClick={() => handleDelete(collectionName)}>delete</button>
    </div>
  );
}
