import { fromTraversable, Lens, Prism, Traversal } from "monocle-ts";
import { array } from "fp-ts/lib/Array";

export type Collection = {
  name: string;
  images: string[];
};

export type CollectionsState = {
  collections: Collection[];
};

export type CollectionsActions = {
  setCollections: (c: Collection[]) => void;
  addCollection: (n: string) => void;
  setImages: (n: string, images: string[]) => void;
  resetCollections: () => void;
};

export type CollectionsContextValue = {
  state: CollectionsState;
  actions: CollectionsActions;
};

export const emptyCollectionsContextValue: CollectionsContextValue = {
  state: {
    collections: [],
  },
  actions: {
    setCollections: () => {},
    addCollection: () => {},
    setImages: () => {},
    resetCollections: () => {},
  },
};

const collectionTraversal = fromTraversable(array)<Collection>();
const getCollectionPrism = (name: string): Prism<Collection, Collection> =>
  Prism.fromPredicate((collection) => collection.name === name);
const getImages = Lens.fromProp<Collection>()("images");

const getImagesTraversal = (name: string): Traversal<Collection[], string[]> =>
  collectionTraversal
    .composePrism(getCollectionPrism(name))
    .composeLens(getImages);

export function setCollectionImages(
  collections: Collection[],
  name: string,
  images: string[]
): Collection[] {
  const imagesTraversal = getImagesTraversal(name);
  return imagesTraversal.set(images)(collections);
}
