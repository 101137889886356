import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Game } from "./game";
import { CollectionEditor } from "./collections-manager/CollectionEditor";
import { WordList } from "./word-list";
import { ReviewList } from "./review-list";
import { useSession } from "../providers/session-provider";
import { LoginSignup } from "./login-signup";

function App() {
  const {
    state: { sessionToken },
  } = useSession();

  if (!sessionToken) {
    return <LoginSignup />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/play" element={<Game />} />
        <Route path="/" element={<WordList />} />
        <Route path="/review" element={<ReviewList />} />
        {/*<Route path="/" element={<CollectionsManager />} />*/}
        <Route path="/coll/:name" element={<CollectionEditor />} />
      </Routes>
    </div>
  );
}

export default App;
