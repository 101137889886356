import style from "./style.module.css";

type Props = {
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
};

export function ModalView({ children, visible, onClose }: Props) {
  if (!visible) {
    return null;
  }

  return (
    <div className={style.container}>
      {children}
      <button onClick={onClose}>close</button>
    </div>
  );
}
